import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';

export const fromDateToDateValidator =
  (fromControlName: string, toControlName: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    const fromControlValue = control.get(fromControlName)!.value;
    const toControlValue = control.get(toControlName)!.value;
    if (!fromControlValue || !toControlValue || fromControlValue === '' || toControlValue === '') {
      return null;
    }
    return DateWrapperUtility.dateAIsBeforeDateB(fromControlValue, toControlValue)
      ? null
      : { fromDateShouldBeLessThanToDate: 'From Date Should Be Before To Date' };
  };
